import React from "react"
import { Helmet } from "react-helmet"
import { DivedownBox } from "../components/divedowns"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Ad from "../components/ad"

import HeaderPic from "../components/headerpic"
import headerPic from "../../static/headerpics/geschlecht.png"



export default function Geschlecht({ data }) {

  return (
    <Layout>
      <Helmet>
        <title>Vornamen nach Geschlecht</title>
        <meta name="description"
          content="Über 16000 Jungen-, Mädchen- und Unisexnamen." />
        <meta name="keywords"
          content="jungennamen, mädchennamen, unisexnamen, weibliche vornamen, männliche vornamen" />
        <link rel="canonical" href="/nach-geschlecht" />
      </Helmet>

      <div className="paper">
        <HeaderPic imagePath={headerPic} />
        <div className="section">
          <h1>Vornamen nach Geschlecht</h1>
          <p>Kennen Sie das Geschlecht Ihres Babys bereits? Mit der swissmom-Namenssuche finden Sie die schönsten Vornamen für Mädchen und Jungen.</p>
        </div>

        <DivedownBox
          divedowns={data.pagesJson.divedowns.filter((divedown) => divedown.attribute.category === 'Geschlecht')}
          initial_limit={100}
        />
      </div>
      <Ad id="adnz_maxiboard_1" />

    </Layout>
  )
}

export const query = graphql`
  query {
    pagesJson(url: {eq: ""}) {
      divedowns {
        attribute {
          imagePath
          category
          title
          id
          latest_births
          trend
        }
        number_of_names
        subpage_url
      }
    }
  }
  `